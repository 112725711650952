/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { SliceZone } from "../components/SliceZone";
import AHLCarousel from "../components/AHLCarousel";
import Socials from "../components/SocialMediaIcons";
// markup
const ActorsHeadshotsLondonPage = ({ data }) => {
  const pageData = data.prismicActorsHeadshotsLondon.data;
  const sliceData = data.prismicActorsHeadshotsLondon.data.body;
  return (
    <Layout>
      <SEO
        title="Actors Headshots London"
        description="Portrait Photographer for Actors Headshots based in Greenwich in South East London. Professional studio photography."
      />
      <div sx={{}}>
        <div
          sx={{
            display: ["block", "none"],
            h1: { fontSize: 4, textAlign: "center" },
          }}
        >
          <h1>{pageData.title.text}</h1>
        </div>

        <section
          sx={{
            display: ["flex", "flex"],
            flexDirection: ["column-reverse", "row"],
            alignItems: "center",
          }}
        >
          <div
            sx={{
              flex: ["1 1 100%", "1 1 50%"],
              fontSize: 4,
              color: "muted",
              h1: {
                color: "black",
              },
              display: "flex",
              flexDirection: "column",

              // "div:last-child": {
              //   flex: 1,
              //   border: "1px red solid",
              //   justifySelf: "flex-end",
              //   marginTop: "auto",
              // },
            }}
          >
            <div sx={{ display: ["none", "block"] }}>
              <h1>{pageData.title.text}</h1>
            </div>

            <PrismicRichText field={pageData.text.richText} />
            <div
              sx={{
                order: [2, 1],
                a: {
                  fontSize: 3,
                  display: "inline-block",
                  textDecoration: "none",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  mr: "10px",
                  color: "white",
                  bg: "muted",
                  mb: 2,
                  transition: "all 0.3s ease",
                  cursor: "pointer",
                  "&:hover, &:focus-within": {
                    bg: "text",
                    color: "white",
                  },
                },
              }}
            >
              {pageData.button_group.map((item, index) => {
                return (
                  <Link key={`buttongroup${index}`} to={item.button_link.url}>
                    {item.button_text.text}
                  </Link>
                );
              })}
            </div>
            <Socials />
          </div>
          <div
            sx={{
              flex: ["1 1 100%", "1 1 50%"],
              width: ["100%", "50%"],
              order: [1, 2],
            }}
          >
            <div sx={{ padding: ["0.3em", "2em"] }}>
              <AHLCarousel images={pageData.carousel} />
            </div>
          </div>
        </section>
        <div sx={{ pb: 3 }}>
          <SliceZone sliceZone={sliceData} />
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(ActorsHeadshotsLondonPage);

export const query = graphql`
  query actorsHeadshotsLondonPageQuery {
    prismicActorsHeadshotsLondon {
      _previewable
      data {
        title {
          richText
          text
        }
        text {
          richText
          text
        }
        button_group {
          button_text {
            text
            html
          }
          button_link {
            id
            url
            uid
            type
            slug
            link_type
          }
        }
        carousel {
          image {
            gatsbyImageData
            alt
          }
          link {
            url
            slug
            link_type
          }
          title1 {
            text
            richText
            raw
          }
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...AHLImagerow
          ...AHLSideimagerow
          ...AHLTextrow
          ...AHLSidetextrow
          ...AHLContactrow
          ...AHLFaqrow
        }
      }
    }
  }
`;
