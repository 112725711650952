/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import Modal from "react-modal";
//import { Global } from "@emotion/core";
import { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaArrowRight, FaTimes } from "react-icons/fa";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const AHLCarousel = ({ images }) => {
  const [modalState, setModalState] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");

  const openModal = (src) => {
    console.log("src", src);
    //stop bg scrolling when modal open - done in global css above now
    // const pic = getImage(src.image);
    setModalImage(src.image);
    setModalName(src.title1);
    setModalLink(src.link);
    // setModalName("yo dude");
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  return (
    <div sx={{ py: 4 }}>
      <Swiper
        modules={[A11y, Autoplay]}
        spaceBetween={15}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        preloadImages={true}
        loop={true}
      >
        {images.map((image, index) => {
          const pic = getImage(image.image);
          //console.log("caption", image.title1.raw.length);
          return (
            <SwiperSlide key={`slide${index}`}>
              <figure sx={headshotStyles} onClick={(e) => openModal(image)}>
                <GatsbyImage
                  image={pic}
                  alt={image.image.alt}
                  sx={{ "& img": { border: "1px solid black" } }}
                />

                {image.title1.text && (
                  <figcaption>{prismicH.asText(image.title1.raw)}</figcaption>
                )}
              </figure>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Modal
        isOpen={modalState}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        contentLabel="Image Lightbox Modal"
      >
        <div
          sx={{
            color: "white",
            display: "flex",
            fontSize: "1.25rem",
            justifyContent: "right",
            cursor: "pointer",
            // marginRight: "2em",
            // marginBottom: "0.3em",
          }}
          onClick={closeModal}
        >
          <FaTimes />
        </div>
        <div
          sx={{
            //width: "1000px",
            maxWidth: "90vw",
            maxHeight: "90vh",
            textAlign: "center",
            "& .gatsby-image-wrapper": {
              width: "100%",
              maxWidth: "95vw",
              height: "100%",
              maxHeight: "85vh",
            },
            "& img": {
              //border: "1px solid black",
              objectFit: "contain !important",
            },
          }}
        >
          <GatsbyImage image={getImage(modalImage)} />
        </div>
        <div
          sx={{
            display: "flex",
            pt: 3,
            justifyContent: "center",
            h2: { bg: "white", color: "black", m: 0, p: 2, lineHeight: 1 },
            a: {
              color: "white",
              fontSize: 5,
              ml: 2,
              lineHeight: 1,
              "&:hover, &:focus-within": { color: "muted" },
            },
            svg: { marginTop: "4px" },
          }}
        >
          <h2>{modalName.text}</h2>
          {!(modalLink.link_type === "Any") && (
            <a href="#">{<FaArrowRight />}</a>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AHLCarousel;

const headshotStyles = {
  m: 0,

  figcaption: {
    fontSize: "1.25rem",
  },
  "@media (hover: hover) and (pointer: fine)": {
    position: "relative",
    transition: "all 0.3s ease",
    cursor: "pointer",
    figcaption: {
      fontSize: "1.4rem",
      fontWeight: "400",
      position: "absolute",
      bottom: 2,
      left: 2,
      zIndex: 5,
      bg: "white",
      px: 2,
      py: 2,
      transition: "all 0.3s ease",
      transform: "translate(0,30px)",
      opacity: 0,
    },
    //hover
    "&:hover, &:focus-within": {
      figcaption: {
        transform: "translate(0,0)",
        opacity: 1,
      },
    },
  },
};
